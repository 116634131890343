import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import {
  AlertMessageComponent,
  InputComponent,
  ShimmerComponent,
  TagComponent,
  NavBarComponent,
  TagContainerComponent,
  LinkComponent
} from './components';
import { TextareaComponent } from './components/textarea/textarea.component';
import { LabelComponent } from './components/label/label.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { AccordionItemComponent } from './components/accordion-item/accordion-item.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { AccessibleClickDirective, DebounceDirective } from './directives';
import { SwitchComponent } from './components/switch/switch.component';
import { FocusableComponent } from './components/focusable';

@NgModule({
  declarations: [
    AlertMessageComponent,
    AccessibleClickDirective,
    DebounceDirective,
    TagComponent,
    TagContainerComponent,
    InputComponent,
    NavBarComponent,
    LinkComponent,
    TextareaComponent,
    LabelComponent,
    ProgressBarComponent,
    AccordionItemComponent,
    AccordionComponent,
    SwitchComponent,
    FocusableComponent,
    ShimmerComponent
  ],
  imports: [CommonModule, FormsModule, MatProgressBarModule],
  exports: [
    AlertMessageComponent,
    TagComponent,
    TagContainerComponent,
    NavBarComponent,
    InputComponent,
    TextareaComponent,
    LinkComponent,
    AccessibleClickDirective,
    LabelComponent,
    ProgressBarComponent,
    AccordionItemComponent,
    AccordionComponent,
    SwitchComponent,
    FocusableComponent,
    ShimmerComponent
  ]
})
export class VIUIModule {}
