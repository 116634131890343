import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vi-rounded-checkbox',
  templateUrl: './rounded-checkbox.component.html',
  styleUrls: ['./rounded-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoundedCheckboxComponent {
  @Input() public show = false;
  @Input() public selected = false;
  @Input() public focused = true;
  // passing ariaLabelledby of the element/component's name the rounded checkbox is referring to
  @Input() public ariaLabelledby = '';
  @Output() public clicked = new EventEmitter<void>();

  constructor() {}

  public handleClick() {
    this.clicked.emit();
  }
}
