<div class="rounded-checkbox"
  [ngClass]="{'selected': selected, 'show': show}"
  [tabindex]="focused ? 0 : -1"
  role="checkbox"
  [attr.aria-labelledby]="ariaLabelledby"
  [attr.aria-checked]="selected"
  (appAccessibleClick)="handleClick()">
  <i class="i-vi-select-cell">
  </i>
</div>
